import React, {useState, useContext} from "react"
import {FirebaseContext} from '../components/Firebase'

import { Form, Button, Input, ErrorMessage } from '../components/common'

const LoginPage = (props) => {

    const [formValues, setFormValues] = useState({email: '', password: ''});
    const {firebase} = useContext(FirebaseContext);
    const [errorMessage, setErroroMessage] = useState('');

    const handleSubmit=(e)=>{
        e.preventDefault(); 
        firebase.login({email: formValues.email, password: formValues.password }).catch(error =>{
             setErroroMessage(error.message);
        })
    }

    const handleImputChange = (e)=>{
        e.persist();

        setFormValues(currentValues =>({
            ...currentValues,
            [e.target.name] : e.target.value
        }))
        setErroroMessage('');
    }

    return(
        <section>
            <Form  onSubmit={handleSubmit} >
                <Input value={formValues.email} name="email" onChange={handleImputChange} placeholder="email" type="email" />
                <Input value={formValues.password} name="password" onChange={handleImputChange} placeholder="password" type="password" />  
                {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Button type="submit" block >Login</Button> 
            </Form>   
        </section>
    )
}


export default LoginPage;
